import { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';

export default function BaseModal(props: IProps) {
  return ReactDOM.createPortal(
    <>
      {/* 遮罩层 */}
      {props.isOpen && <Modal {...props} />}
    </>,
    document.body,
  );
}

export interface IProps {
  children: ReactNode;
  isOpen: boolean;
  showTitle?: boolean;
  hideCloseButton?: boolean;
  isShowCloseButton?: boolean;
  onClose: (data?: any) => void;
  placement?: 'bottom' | 'center' | 'right' | 'left';
  title?: string | ReactNode;
  zIndex?: number
  classNames?: {
    mask?: string;
    modal?: string;
    title?: string;
    content?: string;
    animated?: string; // 设置主体modal的样式
  };
  
  // 点击遮罩是否触发onClose
  clickMaskClose?: boolean;
}
