import {
  ScrollShadow,
  ScrollShadowProps,
  Tab,
  Tabs,
  TabsProps,
  cn,
} from '@nextui-org/react';
import { ReactNode, cloneElement } from 'react';

// 定义传入的数据结构类型
interface TabsOption {
  text: string | ReactNode;
  value: any;
  content?: any;
  [key: string]: any;
}

interface IProps {
  value: string | number;
  fullWidth?: boolean;
  onChange?: (value: any) => void | any;
  options: TabsOption[];
  scrollShadowProps?: ScrollShadowProps;
  contentProps?: any;
  tabsPropsProps?: TabsProps;
  variant?: 'solid' | 'light' | 'underlined' | 'bordered' | undefined;
  classNames?: {
    //整体tabs
    base?: string;
    //当前选中
    active?: string;
    //tabs
    tabs?: string;
    cursor?: string;
  };
}
/**
 * @returns 封装 nextui tabs 组件
 */
export default function BaseTabs(props: IProps) {
  const {
    scrollShadowProps = {},
    tabsPropsProps = {},
    variant = 'underlined',
    contentProps = {},
    options = [],
    fullWidth = false,
    classNames,
  } = props || {};

  const isBorderType = variant === 'bordered';

  return (
    <ScrollShadow
      hideScrollBar
      orientation="horizontal"
      className={`max-w-[100%]  px-0 ${props?.scrollShadowProps?.className}`}
      {...scrollShadowProps}
    >
      <Tabs
        selectedKey={props.value}
        onSelectionChange={props?.onChange}
        variant={variant}
        {...props?.tabsPropsProps}
        classNames={{
          tab: variant === 'underlined' ? 'first:!pl-0' : '',
          tabList: cn({
            underlined: variant,
            '!pl-0': !variant,
            'border-0': isBorderType,
          }),
          cursor: cn(
            variant === 'underlined' ? ' bg-primary w-[20px] h-[3px]' : '',
            // classNames?.active ? classNames?.active : '',
            classNames?.cursor,
          ),

          ...tabsPropsProps?.classNames,
          tabContent: cn({
            'text-xs': isBorderType,
          }),
        }}
        className={cn(`${props?.tabsPropsProps?.className}`, {
          'bg-backgroundAuxiliaryColor rounded-md': isBorderType,
        })}
        fullWidth={fullWidth}
      >
        {options
          ?.filter?.((item) => !!item)
          ?.map?.((item) => {
            return (
              <Tab
                key={item.value}
                title={
                  <div
                    className={`flex items-center  ${
                      +props.value === +item?.value ? `!text-foreground  ${classNames?.active}` : '!font-normal !text-auxiliaryTextColor'
                    } ${classNames?.tabs}`}
                  >
                    {item.text}
                  </div>
                }
              >
                {item?.content && cloneElement(item?.content, contentProps)}
              </Tab>
            );
          })}
      </Tabs>
    </ScrollShadow>
  );
}
