import { FluentTriangleDown12Filled } from '@/assets/icons/comm/FluentTriangleDown12Filled';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import { DisplayUserAgreement } from '@/components/display/displayUserAgreement';
import { GetTextEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { LoginType, WAY_TO_REGISTER } from '@/pages/public/login/useStateHooks';
import { history, useModel } from '@@/exports';
import { Button, Checkbox, cn, Spinner } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
/**
 * 默认导出一个函数组件，用于渲染登录表单。
 * @param props - 组件接收的props，包含用户名和密码的当前状态及其更新函数。
 * @returns 返回一个包含账号和密码输入字段以及登录按钮的div元素。
 */
export default ({
  username,
  setUserName,
  password,
  setPassword,
  submitLogin,
  goForgot,
  goRegister,
  captcha,
  captchaLoading,
  setCaptcha,
  captchaData,
  getCaptchaApiRequest,
  submitLoading,
  checked,
  setChecked,
  setIsRemenber,
  isRemenber,
  currentLogin,
}: LoginType) => {
  const { t } = useTranslation();
  const { appInfo = {} } = useModel('appInfo');
  const { AUTH_LOGIN_CAPTCHA = false } = appInfo;
  const { isMobile } = useModel('system');
  const { currentAreaCode } = useModel('areaCode');

  return (
    <div className=" sm:min-w-[420px]  ">

      {/* 账号输入框 */}
      {currentLogin === WAY_TO_REGISTER.PHONE && (
        <div
          className={cn(
            `flex items-center border-1 box-border h-[56px] shadow-sm  border-[#efefef] rounded-md`,
            {
              'border-[#efefef]': !isMobile,
              'border-borderColor': isMobile,
            },
          )}
        >
          <div
            className="flex items-center  h-full sm:cursor-pointer"
            onClick={() => {
              history.push(
                `${PageEnum.CHOOSE_AREA}?targetUrl=${
                  location.pathname
                }&currentRegister=${currentLogin}&isKeepAlive=${1}`,
              );
            }}
          >
            <img
              className="ml-4 w-[20px] h-[20px] flex-shrink-0 rounded-full  object-cover"
              src={(currentAreaCode as any)?.iconUrl}
              alt=""
            />
            <span>+{(currentAreaCode as any)?.areaCode}</span>
            <FluentTriangleDown12Filled
              className="text-backContrastColor ml-2"
              width="8px"
              height="8px"
            />
          </div>
          <BaseInput
            classNames={{
              input: 'sm:text-[#0E0E0E] !border-none',
              label: 'sm:!text-[#0E0E0E]',
              inputWrapper: 'sm:text-[#0E0E0E] !border-none shadow-none',
            }}
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            label={t('手机号')}
            isRequired
          />
        </div>
      )}
      {currentLogin === WAY_TO_REGISTER.EMAIL && (
        <BaseInput
          value={username}
          onChange={(e) => setUserName(e.target.value)}
          isRequired
          classNames={{
            input: 'sm:text-[#0E0E0E]',
            inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
            label: 'sm:!text-[#0E0E0E]',
          }}
          label={t('邮箱')}
        />
      )}
      {/* 密码输入框 */}
      <BaseInput
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        className="mt-4"
        isRequired
        classNames={{
          input: 'sm:text-[#0E0E0E]',
          inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
          label: 'sm:!text-[#0E0E0E]',
        }}
        label={t('密码')}
      />
      {/* 验证码 */}
      {AUTH_LOGIN_CAPTCHA && (
        <div className="relative mt-4">
          <BaseInput
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
            isRequired={false}
            type="text"
            className="flex-1 "
            classNames={{
              input: 'sm:text-[#0E0E0E]',
              clearButton: 'block opacity-100 right-[120px]',
              innerWrapper:
                'w-[calc(100%-120px)] sm:text-[#0E0E0E] sm:!border-[#efefef]/30',
              inputWrapper: 'sm:text-[#0E0E0E] sm:!border-[#efefef]',
              label: 'sm:!text-[#0E0E0E]',
            }}
            label={t('验证码')}
          />
          <div className="w-[120px] flex w-fit h-full absolute justify-center -mt-[55px] items-center right-[15px]">
            {captchaLoading && (
              <>
                {/* 遮罩 */}
                <div className="absolute inset-y-1 -inset-x-1 bg-black bg-opacity-20 flex items-center justify-center z-[9] h-[39px] " />
                <Spinner
                  size="sm"
                  color="primary"
                  className="absolute w-fit cursor-pointer h-full z-[10]"
                />
              </>
            )}
            <img
              className="w-[120px] absolute right-1 top-1 cursor-pointer"
              src={(captchaData as any)?.img}
              alt=""
              onClick={(e) => {
                getCaptchaApiRequest();
                e.stopPropagation();
                e.preventDefault();
                return false;
              }}
            />
          </div>
        </div>
      )}
      <div className="mt-5 pl-1.5 flex justify-between px-1">
        <Checkbox
          size="sm"
          defaultSelected
          color="default"
          isSelected={isRemenber}
          classNames={{
            wrapper: cn(``, {
              'after:bg-[#f4f4f5] text-[#000] before:border-[#f4f4f5]':
                !isMobile,
            }),
          }}
          onValueChange={setIsRemenber}
          className="pl-1"
        >
          <span
            className={cn(`mr-0.5 text-[10px] `, {
              '!text-auxiliaryTextColor': !isMobile,
            })}
          >
            {t('记住密码')}
          </span>
        </Checkbox>

        <div
          className="text-xs inline-block text-auxiliaryTextColor cursor-pointer"
          onClick={goForgot}
        >
          {t('忘记密码')}
        </div>
      </div>
      {/* 登录按钮 */}
      <Button
        onMouseDown={(event) => {
          // 阻止 Safari 默认的焦点切换行为
          event?.preventDefault?.();
        }}
        onTouchStart={(event) => {
          // 处理触摸设备上的焦点问题
          event?.preventDefault?.();
        }}
        tabIndex={-1}
        spinner={<LoadingSvg />}
        isLoading={submitLoading}
        onClick={submitLogin}
        className="outline-none select-none mt-6 mainColorButton"
      >
        {t('登录')}
      </Button>

      <Button
        onMouseDown={(event) => {
          // 阻止 Safari 默认的焦点切换行为
          event?.preventDefault?.();
        }}
        onTouchStart={(event) => {
          // 处理触摸设备上的焦点问题
          event?.preventDefault?.();
        }}
        onClick={goRegister}
        className="w-full mx-auto rounded-md mt-3 bg-backgroundAuxiliaryColor  h-[45px] sm:!bg-[#f4f4f5] sm:border-none sm:text-[#0E0E0E]"
      >
        {t('注册')}
      </Button>

      <div className="pt-6">
        <div className="flex items-center flex-wrap pl-1.5 pb-[6px]">
          <Checkbox
            size="sm"
            defaultSelected
            color="default"
            isSelected={checked}
            onValueChange={setChecked}
            classNames={{
              wrapper: cn(``, {
                'after:bg-[#f4f4f5] text-[#000] before:border-[#f4f4f5]':
                  !isMobile,
              }),
            }}
            className="p-0 "
          >
            <span
              className={cn(`mr-0.5 text-[10px]`, {
                '!text-auxiliaryTextColor': !isMobile,
              })}
            >
              {t('我已详细阅读并同意')}
            </span>
          </Checkbox>

          <DisplayUserAgreement
            title={t('用户协议')}
            requestKey={GetTextEnum.SYSTEM_USER}
          >
            <span className="inline-block text-darkBrandColor text-[10px] cursor-pointer">
              {`《${t('用户协议')}》`}
            </span>
          </DisplayUserAgreement>

          <DisplayUserAgreement
            title={t('隐私协议')}
            requestKey={GetTextEnum.PRIVACY_CLAUSE_CODE}
          >
            <span className="inline-block text-darkBrandColor text-[10px] cursor-pointer">
              {`《${t('隐私协议')}》`}
            </span>
          </DisplayUserAgreement>
        </div>
      </div>
      {/* 创建账号提示文本 */}

      <br />
      {/* 创建账号提示文本 */}
    </div>
  );
};
