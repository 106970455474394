import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { toast } from '@/layouts/components/ToastMessage';
import {
  getCaptchaApi,
  loginApi,
  sendMailApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import ProjectSetting from '@/setting/projectSetting';
import useUrlState from '@ahooksjs/use-url-state';
import { history, useModel } from '@umijs/max';
import { useDebounceFn, useMount } from 'ahooks';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const isDev = process.env.NODE_ENV === 'development';

// 登录方式
export enum WAY_TO_REGISTER {
  // 邮箱
  EMAIL = '2',
  // 手机号
  PHONE = '1',
}

export default function useStateHooks() {
  const { requestUserInfo } = useModel('user') || {};
  const { t } = useTranslation();
  //是否缓存数据
  const [urlState] = useUrlState<any>({});
  const { isKeepAlive } = urlState || {};
  const [loginOption] = useState([
    {
      text: t('手机号'),
      value: WAY_TO_REGISTER.PHONE,
    },
    {
      text: t('邮箱'),
      value: WAY_TO_REGISTER.EMAIL,
    },
  ]);
  // 当前登录方式
  const [currentLogin, setCurrentLogin] = useState(WAY_TO_REGISTER.PHONE);

  const { appInfo = {} } = useModel('appInfo') || {};

  // useMount(() => {
  //   getPrivacyAgreement({
  //     type: GetTextEnum.PRIVACY_CLAUSE_CODE,
  //     noMsg: true,
  //   });

  //   getUserAgreement({
  //     type: GetTextEnum.SYSTEM_USER,
  //     noMsg: true,
  //   });
  // });
  //发送邮件
  const { run: sendMailApiRequest } = useReq(sendMailApi, {
    manual: true,
  });
  // 用户名--如果缓存中有用户名则取缓存
  const [username, setUserName] = useLocalStorage({
    key: CacheEnum.APP_LOGIN_NAME,
    defaultValue: '',
  });

  // 密码--如果缓存中有密码则解密
  const [password, setPassword] = useLocalStorage({
    key: CacheEnum.APP_LOGIN_PASSWORD,
    defaultValue: '',
  });

  const { AUTH_LOGIN_CAPTCHA = false } = appInfo;

  //验证码
  const {
    data: captchaData = {},
    run: getCaptchaApiRequest,
    loading: captchaLoading,
  } = useReq(getCaptchaApi, {
    onSuccess: (res) => {
    },
    manual: true,
    cacheKey: CacheEnum.APP_CODE,
  });

  useEffect(() => {
    if (AUTH_LOGIN_CAPTCHA) {
      getCaptchaApiRequest();
    }
  }, [AUTH_LOGIN_CAPTCHA]);

  // 验证码
  const [captcha, setCaptcha] = useState('');
  useMount(() => {
    try {
      if (!isKeepAlive) {
        setUserName(Cookies.get(CacheEnum.USER_TOKEN) as any);
        setPassword(
          CryptoJS.AES.decrypt(
            Cookies.get(CacheEnum.USER_PASSWORD) as string,
            ProjectSetting.APP_AES_KEY,
          )?.toString(CryptoJS.enc.Utf8),
        );
        setCaptcha('');
      } else if (isDev) {
        setUserName('123456');
        setPassword('123456');
      }
    } catch (e) {
      setPassword('');
      setUserName('');
    }
  });
  //隐私协议勾选状态
  const [checked, setChecked] = useState(true);
  //记住密码
  const [isRemenber, setIsRemenber] = useState(
    !!Cookies.get(CacheEnum.USER_PASSWORD),
  );
  const { isMobile } = useModel('system');

  //去注册
  const goRegister = () => {
    history.push(PageEnum.REGISTERED);
  };
  //去忘记密码
  const goForgot = () => {
    history.push(PageEnum.FORGOT);
  };

  //请求token
  const [, setToken] = useLocalStorage({
    key: CacheEnum.AUTHORIZATION,
  });

  //刷新token
  const [, setRefreshToken] = useLocalStorage({
    key: CacheEnum.REFRESHTOKEN,
  });
  const { fundingOverviewSocketMapReq } = useModel('user') || {};
  const { currentAreaCode } = useModel('areaCode');
  const [submitLoading, setSubmitLoading] = useState(false);
  //登录提交
  const { runAsync: login, loading: onSubmitLoading } = useReq(loginApi, {
    loadingDefault: false,
    manual: true,
    onSuccess: async (data: any) => {
      /*
       * 判断是否记住密码
       * */
      // 登录成功后 判断是否选择了勾选密码
      if (isRemenber) {
        //添加cookie
        Cookies.set(CacheEnum.USER_TOKEN, username!, {
          expires: 30,
        });
        //使用crypto-js进行加密
        Cookies.set(
          CacheEnum.USER_PASSWORD,
          CryptoJS.AES.encrypt(password, ProjectSetting.APP_AES_KEY),
          {
            expires: 30, // 存储30天
          },
        );
      } else {
        // 删除cookie
        Cookies.remove(CacheEnum.USER_TOKEN);
        Cookies.remove(CacheEnum.USER_PASSWORD);
      }
      /*
       * 保存token等用户信息,跳转登录
       * */
      localStorage.setItem(CacheEnum.APP_LOGIN_TYPE, '2');
      setToken(data?.accessToken);
      if (data?.refreshToken) {
        setRefreshToken(data?.refreshToken);
      }
      // await getExchangeData();
      setTimeout(async () => {
        await fundingOverviewSocketMapReq();
      }, 100);
      localStorage.setItem(CacheEnum.APP_USER_NAME, username!);
      // 订阅登录状态

      // const { sendMsg, isSub } = useModel('socket');

      // sendMsg(
      //   `{"type":"SUB","data": ${JSON.stringify(
      //     '',
      //   )}}`,
      // );

      if (!isMobile) {
        history.push(PageEnum.PCHOME);
        requestUserInfo?.();
        return;
      }

      setSubmitLoading(false);
      history.push(PageEnum.HOME);
    },
    onError: () => {
      setSubmitLoading(false);
      getCaptchaApiRequest();
      setCaptcha('');
    },
  });
  const { run: submitLogin } = useDebounceFn(
    async () => {
      // 以防后端不配置，所以赋默认值为true
      const { AUTH_LOGIN_CAPTCHA = false } = appInfo;
      if (!checked) return toast.error(t('请勾选协议'));
      if (!username?.replace(/\s+/g, '')) {
        return toast.error(
          currentLogin === WAY_TO_REGISTER.PHONE
            ? t('请填写手机号')
            : t('请填写邮箱'),
        );
      }
      if (!password?.replace(/\s+/g, '')) {
        return toast.error(t('请填写密码'));
      }

      if (AUTH_LOGIN_CAPTCHA && !captcha) {
        return toast.error(t('请填写验证码'));
      }
      setSubmitLoading(true);

      // 去掉所有空格
      await login({
        username: username?.replace(/\s+/g, ''),
        password: password?.replace(/\s+/g, ''),
        type: currentLogin,
        // 不需要验证码登录
        AUTH_LOGIN_CAPTCHA: appInfo?.AUTH_LOGIN_CAPTCHA,
        areaCode:
          currentLogin === WAY_TO_REGISTER.PHONE
            ? (currentAreaCode as any)?.areaCode
            : undefined,
        imgCaptcha: captcha,
        uuid: (captchaData as any).uuid,
      });
    },
    {
      wait: 0,
    },
  );

  useMount(() => {
    localStorage.setItem(CacheEnum.APP_LOGIN_TYPE, '2');
  });

  return {
    username, // 用户名
    setUserName, // 设置用户名
    password, // 密码
    setPassword, // 设置密码
    submitLogin, // 提交
    goRegister, // 去注册
    goForgot, // 去忘记密
    captcha,
    setCaptcha, // 设置验证码
    captchaData, // 验证码
    captchaLoading, // 验证码加载
    getCaptchaApiRequest,
    sendMailApiRequest, // 发送邮件
    submitLoading,
    checked,
    setChecked,
    setIsRemenber,
    isRemenber,
    currentLogin,
    setCurrentLogin,
    loginOption,
  };
}

export type LoginType = ReturnType<typeof useStateHooks>;
