import { CarbonCloseOutline } from '@/assets/icons/comm/CarbonCloseOutline';
import { IProps } from '@/components/base/baseModal/index';
import { useModel } from '@@/exports';
import { cn } from '@nextui-org/react';
import { useMount, useUnmount } from 'ahooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import style from './index.less';

export let BaseModalZIndex = 9000;

export default ({
                  children,
                  isOpen,
                  hideCloseButton,
                  onClose,
                  title,
                  classNames = {
                    modal: '',
                    mask: '',
                    title: '',
                    content: '',
                    animated: '',
                  },
                  zIndex:defaultZIndex,
                  showTitle = true,
                  clickMaskClose = true,
                  isShowCloseButton = false,
                  ...otherProps
                }: IProps) => {
  //获取当前是手机还是PC
  const { isMobile } = useModel('system');
  //弹窗位置
  const { placement = isMobile ? 'bottom' : 'center' } = otherProps;
  //modal实例
  const modalRef = useRef<HTMLDivElement>(null);
  const handleCl = (event: MouseEvent) => {
    //判断当前触发事件是不是在children下；以防那种在body下面弹出层也可以下拉刷新
    const isChildren =
      modalRef.current && modalRef.current.contains(event.target as Node);
    if (!isChildren) {
      onClose();
    }
    event.stopPropagation();
  };
  // 层级
  const [zIndex] = useState(defaultZIndex ?? BaseModalZIndex);

  const [isShowAnimation, setIsShowAnimation] = useState(false);
  useMount(() => {
    setIsShowAnimation(true);
    BaseModalZIndex += 2;
    document.body.style.overflow = 'hidden';
  });

  useUnmount(() => {
    BaseModalZIndex -= 2;
    document.body.style.overflow = 'auto';
  });

  // 抽屉动画
  const { y } = useSpring({
    to: { y: isShowAnimation ? 0 : 65 },
    config: { tension: 200, friction: 20 },
  });
  // 方向在左边时的动画
  const { y: leftY } = useSpring({
    to: { y: isShowAnimation ? 0 : -64 },
    config: { tension: 200, friction: 20 },
  });

  const mask = useRef<HTMLDivElement>(null);

  const handleTouchMove = (e: TouchEvent) => {
    const isChildren = mask.current && mask.current.contains(e.target as Node);
    if (!isChildren) {
      e.preventDefault();
      return false;
    }
    // e.preventDefault();
  };
  
  useEffect(() => {
    if (mask.current) {
      mask.current?.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      });
    }
    return () => {
      if (mask.current) {
        mask.current?.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, []);

  return (
    <div
      className={cn(
        `lh-modal fixed z-[999] bottom-0 right-0 flex left-0 top-0 text-foreground  overflow-hidden bg-[#000]/50 ${classNames?.mask}`,
        {
          'flex-col justify-end': placement === 'bottom',
          'justify-center items-center': placement === 'center',
          'justify-end': placement === 'right',
          'justify-start': placement === 'left',
        },
      )}
      ref={mask}
      style={{ zIndex }}
      onClick={(event) => {
        clickMaskClose ? handleCl(event as any) : null;
      }}
    >
      {/* modal框子 */}
      <div
        ref={modalRef}
        className={cn(
          `lh-modal-content overflow-y-auto centerModal bg-background overflow-hidden border-t-borderColor  pt-2 ${classNames?.modal}`,
          {
            'h-full rounded-none':
              placement === 'right' || placement === 'left',
            [`rounded-t-[12px]  ${style.bottomModal}`]: placement === 'bottom',
            [`rounded-[12px] max-w-[80vw] sm:w-[500px] ${style.centerModal}`]: placement === 'center',
          },
        )}

      >
        {/* 标题 */}

        {showTitle && (
          <div
            className={`flex  items-center justify-center my-2 px-4 w-full mb-2 `}
          >
            <span></span>
            <span className="text-[16px] text-backContrastColor ">
                {title && title}
              </span>

            {!hideCloseButton && !isMobile && isShowCloseButton && (
              <CarbonCloseOutline
                width="18px"
                height="18px"
                className="sm:cursor-pointer text-foreground right-4 absolute"
                onClick={() => {
                  onClose();
                }}
              />
            )}
          </div>
        )}

        {/* 内容 */}
        <div className={` ${classNames?.content}`}>{children}</div>
      </div>
    </div>
  );
};
