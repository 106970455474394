import { GetTextEnum } from '@/enums/businessEnum';
import { getPrivacyAgreementApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { ReactNode } from 'react';
import PrivacyAgreementDrawer from './PrivacyAgreementDrawer';

/**
 *
 * 显示 后端配置的 国际化文本协议
 * title 抽屉打开的标题
 * requestKey 后端需要的key，对应某一个协议规则
 */
export const DisplayUserAgreement = ({
  requestKey,
  title,
  children,
}: IProps) => {
  const {
    data: userAgreement = {},
    runAsync,
    loading,
  } = useReq(
    () =>
      getPrivacyAgreementApi({
        type: requestKey,
        noMsg: true,
      }),
    {
      manual: true,
      cacheKey: 'UserAgreement_' + requestKey,
    },
  );

  return (
    <PrivacyAgreementDrawer
      title={title}
      privacyAgreement={userAgreement}
      runAsync={runAsync}
      loading={loading}
    >
      {children}
    </PrivacyAgreementDrawer>
  );
};

interface IProps {
  children: ReactNode;
  requestKey: GetTextEnum;
  title: string;
}
