import FeaturesNotLoggedInLayout from '@/components/features/featuresNotLoggedInLayout';
import LoginContentForm from '@/pages/public/login/components/LoginContentForm';
import useStateHooks from '@/pages/public/login/useStateHooks';
import { useTranslation } from 'react-i18next';
import BaseTabs from '@/components/base/baseTabs';
import { useModel } from '@@/exports';

/**
 *登录
 */
const LoginView = () => {
  const props = useStateHooks();
  const { t } = useTranslation();
  const { isMobile } = useModel('system');

  return (
    <FeaturesNotLoggedInLayout title={t('登录账户')}>
      <div className="mb-4">
        <BaseTabs
          tabsPropsProps={{
            className: 'w-full ',
            classNames: {
              base: isMobile
                ? 'bg-backgroundAuxiliaryColor'
                : '!bg-[transparent]',
              tabList: 'w-full border-[1px] bg-backgroundColor',
            },
          }}
          classNames={{
            active: isMobile ? '' : '!bg-backgroundAuxiliaryColor/10',
          }}
          value={props.currentLogin}
          variant="bordered"
          onChange={props.setCurrentLogin}
          options={props.loginOption}
        />
      </div>
      <LoginContentForm {...props} />
    </FeaturesNotLoggedInLayout>
  );
};
export default LoginView;
