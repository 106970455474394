import { useModel } from '@@/exports';
import { cn } from '@nextui-org/react';
import { ReactNode } from 'react';

export default ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  const { isMobile } = useModel('system');

  return (
    <div
      className={cn(`items-center flex z-[3] top-0 bottom-0 w-full`, {
        'bg-white fixed': !isMobile,
      })}
    >
      <div
        className={cn(
          `flex-1 h-full bg-[#0E0E0E] w-full   items-center justify-center`,
          {
            flex: !isMobile,
            hidden: isMobile,
          },
        )}
      >
        <img
          className="w-[365px]"
          src={require('@/assets/img/login_pc_bg.png')}
        />
      </div>
      <div
        className={cn(`w-full px-4`, {
          'flex-shrink-0 min-w-[800px] flex-1 ': !isMobile,
        })}
      >
        <div className="sm:w-[420px] mx-auto">
          {/*标题*/}
          <h1
            className={cn(`!mt-10 bigTitleText mb-8  select-none`, {
              'text-[#0E0E0E] text-left !font-semibold	': !isMobile,
              'text-backContrastColor	': isMobile,
            })}
          >
            {title}
          </h1>
          {/*内容*/}
          {children}
        </div>
      </div>
    </div>
  );
};
